<template>
    <w-entry-preview
        v-if="item"
        mini
        icon="COURSE"
        :title="item.title"
        :note="item.description"
        :overline="item.department.title"
        outlined
    >
        <template v-if="isMobile && totalCapacity" #footer>
            <v-divider class="my-2"/>
            <div class="text-truncate  mt-2 d-flex align-center">
                <w-icon color="primary" size="18" left value="CAPACITY"/>
                <span class="font-weight-semibold text-base">{{ item.total_capacity }} {{ $trans('hours') }}</span>
            </div>
        </template>
        <template #actions>
            <course-total-capacity
                v-if="!isMobile && totalCapacity"
                :item="item"
                class="mr-4 mt-1"
            />
            <slot name="actions"></slot>
        </template>
    </w-entry-preview>
    <v-card
        v-else
        class="pa-3"
    >
        <v-skeleton-loader
            type="list-item-avatar"
        ></v-skeleton-loader>
    </v-card>
</template>

<script>
import CourseTotalCapacity from '@apps/school/components/Course/CourseTotalCapacity'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'CourseHead',
    mixins: [mobileBreakpointChecker],
    components: { CourseTotalCapacity },
    props: {
        item: {
            type: Object,
            default: null
        },
        totalCapacity: {
            type: Boolean,
            default: false
        }

    }
}
</script>

<style lang=scss>

</style>
