<template>
    <div class="d-flex align-center">
        <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
        >
            <w-icon
                dark
                color="white"
                size="30"
                value="CAPACITY"
            />
        </v-avatar>
        <div class="ms-3">
            <p class="text-xs mb-0">
                {{ $trans('Total capacity') }}
            </p>
            <h3 class="text-xl font-weight-semibold">
                {{ item.total_capacity }} {{ $trans('hours') }}
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseTotalCapacity',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    }
}
</script>

<style lang=scss>

</style>
